<template>
  <vue-final-modal :value="true" name="select-catalog-modal" hide-overlay>
    <div :class="$style.selectCatalogContainer">
      <div :class="$style.selectCatalogContent">
        <h1 :class="$style.contentHeadline">Catalogs</h1>

        <div v-if="fetchCatalogsStatusPending">
          <BaseSpinner show />
        </div>
        <div v-if="fetchCatalogsStatusError">
          <p>
            There was a problem while fetching catalogs. Try refreshing your
            browser or contact support.
          </p>
        </div>
        <div v-if="fetchCatalogsStatusSuccess">
          <table :class="$style.catalogsTable">
            <thead>
              <tr>
                <th></th>
                <th>name</th>
                <th>last updated</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="catalog of catalogs" :key="catalog.id">
                <td>
                  {{ catalog.record_count }}
                </td>
                <td>
                  <router-link :to="`/catalogs/${catalog.id}/search`">{{
                    catalog.name
                  }}</router-link>
                </td>
                <td>
                  {{ catalog.lastUpdated }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { fetchCatalogs } from "@/api/catalogApi";
import { withAsync } from "@/helpers";
import { API_STATUS } from "@/constants/apiStatus";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "SelectCatalogModal",
  data() {
    return {
      fetchCatalogsStatus: IDLE,
      catalogs: [],
    };
  },
  created() {
    this.fetchCatalogs();
  },
  computed: {
    ...apiStatusComputedFactory("fetchCatalogsStatus"),
  },
  methods: {
    async fetchCatalogs() {
      this.fetchCatalogsStatus = PENDING;
      const { response, error } = await withAsync(() => fetchCatalogs());
      console.log("res", response, error);
      if (error) {
        this.fetchCatalogsStatus = ERROR;
        return;
      }
      this.catalogs = response.catalogs;
      this.fetchCatalogsStatus = SUCCESS;
    },
  },
};
</script>

<style lang="scss" module>
.selectCatalogContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.selectCatalogContent {
  background: white;
  width: 100%;
  max-height: 600px;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(59, 44, 44, 0.06);
  border: 1px solid #e5e7eb;

  @media (min-width: 768px) {
    max-width: 600px;
  }
}

.contentHeadline {
  margin-bottom: 1.5rem;
}

.catalogsTable {
  thead th {
    display: table-cell !important;
    padding: 0;
    color: $color-grey-light;
  }
}
</style>