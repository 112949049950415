<template>
  <div>
    <div :class="$style.logoContainer">
      <img
        :class="$style.logo"
        src="@/assets/img/logo-sm.png"
        alt="Rightsholders Logo"
      />
    </div>
    <div :class="$style.countriesSVGContainer">
      <CountriesSVG />
    </div>
    <SelectCatalogModal />
  </div>
</template>
<script>
import CountriesSVG from "../world/worldSearch/countries.svg?inline";
import SelectCatalogModal from "./components/SelectCatalogModal";

export default {
  name: "SelectCatalog",
  components: {
    CountriesSVG,
    SelectCatalogModal,
  },
};
</script>
<style lang="scss" module>
.logoContainer {
  z-index: 10;
  position: absolute;
  top: 5rem;
  right: 5rem;
  width: 10rem;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.logo {
  max-width: 100%;
  display: block;
  height: auto;
}

.countriesSVGContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all ease-in-out;
  will-change: transform;
}
</style>