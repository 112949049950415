import { functions } from '@/config/firebase.config';

export const fetchCatalogs = () => {
  return functions
    .httpsCallable('fetchCatalogs')()
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const searchCatalogTracks = (catalogId, query) => {
  return functions
    .httpsCallable('searchCatalogTracks')({
      catalogId,
      query,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};
